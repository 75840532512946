import * as React from "react";
import Img from "gatsby-image/withIEPolyfill";
import Layout from "../components/global/layout/layout";
import { graphql } from 'gatsby';

export const pageQuery = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
        description
        titleSuffix
        titleDivider
      }
    }
    file(name: {eq: "anna-weber-nilges"}) {
        childImageSharp {
            fluid {
                ...GatsbyImageSharpFluid_withWebp_noBase64
            }
        }
    }
  }
`;

export default props => {

  return (
    <Layout title={props.data.site.siteMetadata.title}
            description={props.data.site.siteMetadata.description}
            titleSuffix="Über Anna Weber-Nilges"
            titleDivider={props.data.site.siteMetadata.titleDivider}>
      <div className="container">
        <div className="row mt-5">
          <div className="col-12">
            <img className="d-none d-lg-block pl-lg-4 float-right" src="/images/index/anna-weber-nilges.jpg" alt="Anna Weber-Nilges"/>
            <h2 className="">
              Hallo, ich bin Anna Weber-Nilges
            </h2>
            <p className={`mt-4`}>
              Ich bin Anna Weber-Nilges, geboren in Bonn,  und habe in Heidelberg Musiktherapie studiert. 5 Jahre habe ich danach in der Erwachsenenpsychiatrie in Waldbreitbach als Musiktherapeutin gearbeitet. Nach Beendigung meiner Ausbildung zur Kinder- und Jugendlichenpsychotherapeutin arbeitete ich 6 Monate als solche in der Kinder- und Jugendlichenpsychiatrie in Köln, bevor ich mich 2014 in eigener Praxis niederließ.
            </p>
            <p className={`pt-2 mb-0`}>
              Seither arbeite ich mit viel Freude mit Kindern, ihren Eltern, Jugendlichen und jungen Erwachsenen. Ich arbeite tiefenpsychologisch fundiert:  Die Erfahrungen, die wir in unserem Leben gemacht haben,  hemmende wie fördernde, tragen wir als Erlebens- und Verhaltensmuster mit uns. In der tiefenpsychologischen Behandlung geht es mir darum, diese Muster gemeinsam zu entdecken und herauszufinden, wie sie bis heute unser Leben beeinflussen.  Gleichzeitig sollen sich kreative Potentiale entfalten dürfen, um Veränderung zu ermöglich.
            </p>
          </div>
          <div className="col-12 d-lg-none my-4">
            <Img fluid={props.data.file.childImageSharp.fluid}
                 objectFit="cover"
                 objectPosition="50% 50%"
                 alt="Bild von Anna Weber-Nilges"></Img>
          </div>
          <div className="col-12">
            <p className={`mt-0 mt-lg-4 mb-0`}>
              Psychische Probleme entstehen meist in belasteten Beziehungen und können in stärkenden Beziehungen Heilung erfahren. Daher ist es mir in meiner Arbeit zentrales Anliegen, die Beziehung zwischen Kindern und ihren Eltern zu stärken und selbst eine vertrauensvolle Beziehung zu meinen Klienten aufzubauen. Je jünger die Kinder sind, desto wichtiger ist die Elternarbeit. Jugendliche - spätestens im Alter von 15 Jahren- entscheiden mit, ob die Eltern in die Therapie einbezogen werden.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}
